import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { VehicleMutationTypes } from '@/core/enums/Vehicles/Mutations';
import { VehicleActionTypes } from '@/core/enums/Vehicles/Actions';
import { ManufaturersActionTypes } from '@/core/enums/Manufacturers/Actions';
import { LinkTypes } from '@/core/enums/Links';
import ApiService from '@/core/services/ApiService';
import Manufacturers from '@/core/services/car/Manufacturers';
import Qoute from '@/core/services/car/Qoutes';
import moment from "moment"

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [VehicleActionTypes.GET_VEHICLE](
    { commit }: AugmentedActionContext,
  ): Promise<boolean>;

  [VehicleActionTypes.POST_VEHICLE_LEAD](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [VehicleActionTypes.GET_VEHICLE]({ commit }) {
    return new Promise(() => {

        const vehicle = {
          car_year: new Date().getFullYear(),
          is_new: 2,
          manufacturer_id: null,
          model_id: null,
          trim_level_id: null,
          registration_emirate: null,
          phone_number: null,
          email: null,
          name: null,
          phone_country_code: '+971',
          car_values: 0,
          is_car: 1,
          lead_source: 'website',
          btm_source: '',
          utm_source: '',
          utm_medium: '',
          utm_campaign: '',
          utm_content: '',
          sid: '',
          customer_source_url: '',
          is_wizard: true
        };
        commit(VehicleMutationTypes.SET_VEHICLE, vehicle);
        return true;

    });
  },

  async [VehicleActionTypes.POST_VEHICLE_LEAD](context, payload: any) {
    const source = window.localStorage.getItem('sources')
    const renewal = window.localStorage.getItem('renewal')
    if(renewal === '1')
    {
       // The lead is renewal lead
       payload.renewal = 1;
       window.localStorage.removeItem('renewal') // to avoid logical issues in sessions
    }
    context.commit(VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE, true);
    try {
      const data = await ApiService.post(LinkTypes.POST_VEHICLE_LEAD, payload);
      const uuid = data.data.data.data.uuid.toString().replace('null', '')
      if(context.rootState.drivers.driver != undefined) {
        context.rootState.drivers.driver.uuid = uuid
        context.rootState.drivers.driver.car_value = payload.car_values
        context.rootState.drivers.driver.is_new = payload.is_new
      }
      window.localStorage.setItem('fall_back', uuid);
      //payload.customer_source_url = "/vehicle-details";
      window.localStorage.setItem('customer_detatils', JSON.stringify(payload));
      context.commit(VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE, false);
      let link = `/driver-details?uuid=${uuid}`;
      if(data.status === 200) {
        if(source) link = `${link}${source}`
        if(data.data.data.data.go_back_wizard) {
          const customer = JSON.parse(window.localStorage.getItem('customer_detatils') || '')
          customer.sid = data.data.data.data.sid
          window.localStorage.setItem('customer_detatils', JSON.stringify(customer))
          link = `${link}&sid=${data.data.data.data.sid}`
        }

        window.location.href = link
      }
      // router.push({name: 'DriverDetails', query: {uuid: data.data.data.data.uuid, sid: payload.sid}})
      return data
    } catch (err) {
      return err;
    }
  },

  async [VehicleActionTypes.GET_CUSTOMER](context, payload: any) {
    try {
      const { data } = await ApiService.post(LinkTypes.GET_CUSTOMER, payload);
      console.log('payload',{payload})
      window.localStorage.setItem('fall', payload.uuid);
      const result = data.data.customer;
      console.log('result',result)
      console.log('data',data)
      const customer_details = data.data.customer;
      console.log('customer_details',customer_details)
      const driver_details = data.data.driver_details;
      if(result) {
        const phone_number = result.phone_number_without_code;
        result.phone_number = phone_number;
        result.phone_country_code = result.phone_country_code;
        result.car_year = (result.car_year != undefined) ? result.car_year :new Date().getFullYear();
        result.is_new = result.latest_car_lead ? result.latest_car_lead.is_new : 2;
        result.is_car = 1;
        result.manufacturer_id = (result.manufacturer_id != undefined) ? result.manufacturer_id : -1;
        result.model_id = (result.model_id != undefined) ? result.model_id : -1;
        result.trim_level_id = (result.trim_level_id != undefined) ? result.trim_level_id : -1;
        result.registration_emirate = (result.registration_emirate != undefined) ? result.registration_emirate : -1;
        result.email = result.email;
        result.car_values = '';
        result.lead_source = 'website';
        // result.latest_bike_lead = result.latest_bike_lead;
        // result.bike_year = new Date().getFullYear();
        result.engine_capacity = (result.trim_level_id != undefined) ? result.trim_level_id : -1;
        result.nationality = (result.nationality != undefined) ? result.nationality : null;
        delete result.id;
        delete result.created_at;
        delete result.current_visited_at;
        delete result.current_visited_date_format;
        delete result.joining_date;
        delete result.last_visited_at;
        delete result.last_visited_date_format;
        delete result.updated_at;
        delete result.latest_car_lead_task;
        delete result.is_health;
        delete result.deleted_at;
        delete result.deleted_by;
        delete result.hot_lead;
        delete result.hot_renewal_lead;
        delete result.invoice_increment_no;
        delete result.agent;
        delete result.class_text;
        delete result.created_by;
        delete result.customer_origin_device;
        delete result.customer_origin_lang;
        delete result.customer_source;
        delete result.customer_status_id;
        delete result.customer_status_text;
        delete result.is_migrated;
        delete result.latest_car_lead;
        delete result.lead_count;
        delete result.lead_open_due_date;
        delete result.lead_open_due_date_format;
        delete result.lead_open_task_coun;
        delete result.password;
        delete result.session_id;
        delete result.updated_by;
        delete result.whatsapp_number;

        result.vehicle_type = 1;
        
        if(payload.vehicle_type == 2)
        {
          
          result.bike_year = (result.bike_year != undefined) ? result.bike_year :new Date().getFullYear();
          result.engine_capacity = (result.engine_capacity != undefined) ? result.engine_capacity : -1;;
          result.policy_start_date = moment().format('YYYY-MM-DD');
          // result.policy_start_date_month = new Date().getMonth() + 1;
          // result.policy_start_date_day = new Date().getDate();
          // result.policy_start_date_year = new Date().getFullYear();
          result.dob = (result.dob != undefined) ? result.dob : null;
          // result.dob_month = null;
          // result.dob_day = null;
          // result.dob_year = null;
          result.vehicle_type = 2;

          

          context.dispatch(ManufaturersActionTypes.GET_ENGINE_CAPCITY, {year: result.bike_year, model_id:701})

          context.commit(VehicleMutationTypes.SET_BIKE, {...result});
        }
        else
        {
           context.dispatch(ManufaturersActionTypes.GET_MANUFACTURERS, {year: result.car_year})
           context.commit(VehicleMutationTypes.SET_VEHICLE, result);
        }
       

       

        if(payload && !payload.cid) {
          window.localStorage.removeItem('driver_details');
        }
        window.localStorage.removeItem('qoutes');


        const storecustomer_detatils = window.localStorage.getItem('customer_detatils');
        // console.log('storecustomer_detatils',storecustomer_detatils)
        const customer_detatils = storecustomer_detatils != undefined ? JSON.parse(storecustomer_detatils) : null;
        if(customer_details) {
          window.localStorage.setItem('customer_detatils', JSON.stringify(customer_details))
          context.commit(VehicleMutationTypes.SET_VEHICLE, customer_details);
        }
        
        if(customer_detatils) {
          result.registration_emirate = result.registration_emirate;
          result.manufacturer_id = customer_detatils.manufacturer_id;
          result.car_year = customer_detatils.car_year;
          result.model_id = customer_detatils.model_id;
          result.trim_level_id = customer_detatils.trim_level_id;
          result.car_values = customer_detatils.car_values;
          result.email = customer_detatils.email;

          window.localStorage.setItem('customer_detatils', JSON.stringify(customer_details))
          context.commit(VehicleMutationTypes.SET_VEHICLE, result);
          
          context.dispatch('GET_ESTIMATED_VALUES', {
            car_year: result.car_year,
            manufacturer_id: result.manufacturer_id,
            model_id: result.model_id,
            trim_level_id: result.trim_level_id
          })

          if(payload.vehicle_type == 2)
          {
            // const todayMonth = new Date().getMonth() + 1;
            // const todayDate = new Date().getDate();
            // const todayYear = new Date().getFullYear();

            result.nationality = customer_detatils.nationality;
            result.bike_year =  customer_detatils.bike_year;
            result.engine_capacity = customer_detatils.engine_capacity;
            result.policy_start_date = customer_detatils.policy_start_date || moment().format('YYYY-MM-DD');
            // result.policy_start_date_month = customer_detatils.policy_start_date_month;
            // result.policy_start_date_day = customer_detatils.policy_start_date_day;
            // result.policy_start_date_year = customer_detatils.policy_start_date_year;
            result.dob = customer_detatils.dob;
            // result.dob_month = customer_detatils.dob_month;
            // result.dob_day = customer_detatils.dob_day;
            // result.dob_year = customer_detatils.dob_year;
            context.dispatch(ManufaturersActionTypes.GET_ENGINE_CAPCITY, {year: result.bike_year, model_id:701})
            context.commit(VehicleMutationTypes.SET_BIKE, result);

          }
          else
          {
            
            context.dispatch(ManufaturersActionTypes.GET_MANUFACTURERS, {year: result.car_year})
            context.commit(VehicleMutationTypes.SET_VEHICLE, result);
          }
          
        }

        // return data.data.customer;
      }

      if(payload && payload.cid) {
        window.localStorage.setItem('driver_details', JSON.stringify(driver_details))
        
        // console.log('driver_details',JSON.stringify(driver_details))
      }
      if(customer_details) {
      
        //  window.localStorage.removeItem('customer_detatils');
        if(payload && !payload.cid) {
         window.localStorage.removeItem('driver_details');
        }
         window.localStorage.removeItem('fall');
         window.localStorage.removeItem('fall_back');
         window.localStorage.removeItem('fall_back_dd');
        window.localStorage.setItem('customer_detatils', JSON.stringify(customer_details))
        context.commit(VehicleMutationTypes.SET_VEHICLE, customer_details);
        return customer_details;
      }

    } catch(err) {
      // context.dispatch(VehicleActionTypes.GET_VEHICLE);
      return err
    }
  },

  async [VehicleActionTypes.POST_BIKE_LEAD](context, payload: any) {
    const source = window.localStorage.getItem('sources')
    const renewal = window.localStorage.getItem('renewal')
    if(renewal === '1')
    {
       // The lead is renewal lead
       payload.renewal = 1;
       window.localStorage.removeItem('renewal') // to avoid logical issues in sessions
    }
    context.commit(VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE, true);
    try {
      const data = await ApiService.post(LinkTypes.POST_BIKE_LEAD, payload);
      const uuid = data.data.data.data.uuid.toString().replace('null', '')
      
      
      const result = await Qoute.sendBikeCheapestQuotes({
        uuid:uuid,
        lang: window.localStorage.getItem('language')
      });
      /*
      if(context.rootState.drivers.driver != undefined) {
        context.rootState.drivers.driver.uuid = uuid
        context.rootState.drivers.driver.car_value = payload.car_values
        context.rootState.drivers.driver.is_new = payload.is_new
      }*/
      window.localStorage.setItem('fall_back', uuid);
      console.log('BikeDetails')
      //payload.customer_source_url = "/bike-details";
      window.localStorage.setItem('customer_detatils', JSON.stringify(payload));
      context.commit(VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE, false);
      let link = `/bike-result-listing?uuid=${uuid}`;
      if(data.status === 200) {
        if(source) link = `${link}${source}`
        if(data.data.data.data.go_back_wizard) {
          const customer = JSON.parse(window.localStorage.getItem('customer_detatils') || '')
          customer.sid = data.data.data.data.sid
          window.localStorage.setItem('customer_detatils', JSON.stringify(customer))
          link = `${link}&sid=${data.data.data.data.sid}`
        }

        window.location.href = link
      }
      return data
    } catch (err) {
      return err;
    }
  },
};
