// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum QoutesMutationTypes {
    SET_COMPREHENSIVES = 'SET_COMPREHENSIVES',
    SET_THIRD_PARTIES = 'SET_THIRD_PARTIES',
    SET_COMPARE_DATA = 'SET_COMPARE_DATA',
    SET_COMPARED_DATA = 'SET_COMPARED_DATA',
    SET_IS_LOADING = 'SET_IS_LOADING',
    SET_NO_RECORD = 'SET_NO_RECORD'
  }
  