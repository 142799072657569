import { MutationTree } from 'vuex';

import { Checkout, Payment } from '@/core/types/Checkouts';

import { State } from './state';
import { CheckoutMutationTypes } from '@/core/enums/Checkouts/Mutations';

export type Mutations<S = State> = {
  [CheckoutMutationTypes.SET_CHECKOUT](state: S, payload: Checkout): void;
  [CheckoutMutationTypes.SET_PAYMENT](state: S, payload: Payment): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [CheckoutMutationTypes.SET_CHECKOUT](state: State, checkout: Checkout) {
    state.checkout = checkout;
  },
  [CheckoutMutationTypes.SET_PAYMENT](state: State, payment: Payment) {
    state.payment = payment;
  },
};
