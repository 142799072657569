import { Checkout, Payment } from '@/core/types/Checkouts';


export type State = {
  checkout: Checkout | null;
  payment: Payment | null;
}

export const state: State = {
  checkout: {
    uuid: "",
    url: "",
    coupon: "",
    policy: {},
    quote: {}

  },
  payment: null,
};