import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { TrimLevelsMutationTypes } from '@/core/enums/TrimLevels/Mutations';
import { TrimLevelsActionTypes } from '@/core/enums/TrimLevels/Actions';
import { LinkTypes } from '@/core/enums/Links';
import axios from 'axios';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [TrimLevelsActionTypes.GET_TRIM_LEVELS](
    { commit }: AugmentedActionContext,
    payload: number
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [TrimLevelsActionTypes.GET_TRIM_LEVELS]({ commit }, payload : any) {
    const data = await axios.post(LinkTypes.GET_TRIM_LEVELS, payload);
    try {
      commit(TrimLevelsMutationTypes.SET_TRIM_LEVELS, data.data.data.trim_level);
    }
    catch(err) {
      console.log(err)
    }
  },
};
