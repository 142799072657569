import { Comprehensive, ThirdParty } from '@/core/types/Qoutes';


export type State = {
  comprehensive: Array<Comprehensive> | null;
  thirdParty: Array<ThirdParty> | null;
  loading: Boolean,
  compareData: Array<Object> | null
  isCompareOpen: Boolean | null
  isDisable: Boolean | null,
  checkCompare: Array<string>,
  comparedData: Array<Comprehensive> | null,
  isLoading: Boolean,
  hasData: Boolean
}

export const state: State = {
  comprehensive: [],
  thirdParty: [],
  loading: false,
  compareData: [],
  isCompareOpen: false,
  isDisable: false,
  checkCompare: [],
  comparedData: [],
  isLoading: false,
  hasData: true
};
