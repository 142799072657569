import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { CheckoutMutationTypes } from '@/core/enums/Checkouts/Mutations';
import { CheckoutsActionTypes } from '@/core/enums/Checkouts/Actions';
import { LinkTypes } from '@/core/enums/Links';
import { Checkout } from '@/core/types/Checkouts';
import axios from 'axios';
import router from '@/router';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [CheckoutsActionTypes.POST_CHECKOUT](
    { commit }: AugmentedActionContext,
    payload: any
  ): any;
  [CheckoutsActionTypes.GET_PAYFORT_FORM](
    { commit }: AugmentedActionContext,
    payload: number
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CheckoutsActionTypes.POST_CHECKOUT]({ }, payload) {
    try {
      const source = window.localStorage.getItem('sources')
      console.log('payload',payload.policy.policy_for)
      const data = await axios.post(LinkTypes.POST_CHECKOUT, payload);
      window.localStorage.setItem('form', JSON.stringify(data.data));
      const path = source?.slice(1)
      // router.push({name: 'Payment'})
      if(payload.policy !== undefined && payload.policy.policy_for  && payload.policy.policy_for == 2)
      {
        window.location.href = process.env.VUE_APP_PAYMENT_GATEWAY_URL + '/' + payload.language + '/payments/bike/' + payload.quote.merchant_reference + '/db-deep-chr' + '?' + path
      }
      else
      {
        window.location.href = process.env.VUE_APP_PAYMENT_GATEWAY_URL + '/' + payload.language + '/payments/car/' + payload.quote.merchant_reference + '/db-deep-chr' + '?' + path
      }

      
      return data;
    }
    catch(err) {
      console.log(err)
      return err;
    }
  },

  async [CheckoutsActionTypes.GET_PAYFORT_FORM]({commit}, payload) {
    const data = await axios.post(LinkTypes.GET_PAYFORT_DATA, payload);
    try {
      commit(CheckoutMutationTypes.SET_PAYMENT, data.data);
    } catch (error) {
      console.log(error)
    }
  }

};
